.external-badge {
    display: inline-block;
    border-radius: 3px;
    font-size: 10px;
    padding: 1px 4px;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
    transform: scale(.85);
    text-transform: uppercase;
    white-space: nowrap;
    background-color: #e9e9ee;
    color: #5e5e70;
}