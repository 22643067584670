@import "../../../assets/styles/common-mixin.scss";
@import "./Upcoming-dropdown.scss";

.upcoming {
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 440px;
    border: 1px solid #ededf4;
    border-radius: 8px;
    overflow: hidden;
    color: #ffffff;
    
    
}

/*********************** upcoming header*/
.upcoming__header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 144px;
    background: 0 0 / 100% 144px no-repeat url(../../../assets/images/upcoming-bg.png), #354b61;
}

.upcoming__header-time {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
}

.upcoming__header-day {
    margin-top: 4px;
    font-size: 13px;
    line-height: 16px;
}

/*********************** upcoming content */
.upcoming__content {
    color: #232333;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    overflow-y: auto;
    @include scroll-bar();
}

.upcoming__content-empty {
    width: 100%;
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    
    font-size: 14px;
}

/*****************************************/
.upcoming__actions-popup {
    width: 125px;
    font-size: 13px;
    cursor: pointer;
}

.upcoming__actions-popup-item {
    padding: 0 10px;
    height: 30px;
    &:hover {
        color: #fff;
        background-color: #0e71eb;
    }
}

/*********************** upcoming detail */
.upcoming__detail {
    padding: 0 24px;
    color: #232333;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    cursor: default;
}

.upcoming__detail-topic-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.upcoming__detail-topic {
    flex: 1 1 auto;
    width: 0;
    padding-right: 4px;
    padding-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #39394d;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    cursor: pointer;
}

.upcoming__detail-topic-private {
    span {
        margin-right: 8px;
    }
}

.upcoming__detail-btns {
    flex: 0 0 auto;
}

.upcoming__actions {
    display: flex;
    align-items: center;
}
.upcoming__detail-btn-more {
    margin-right: 10px;
    padding: 0 6px;
}

.upcoming__detail-time,
.upcoming__detail-host,
.upcoming__detail-mn {
    margin-top: 16px;
}

.upcoming__detail-time-divider {
    margin: 0 8px;
    display: inline-block;
    height: 12px;
    width: 1px;
    background-color: #232333;
    vertical-align: middle;
}

/*********************** upcoming item*/
.upcoming__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 15px;
    & > * {
        flex: 0 0 auto;
        margin: 0px 5px;
    }
}

.upcoming__item-info {
    flex: 1 1 auto;
    width: 0;
    cursor: default;
}

.upcoming__item-topic,
.upcoming__item-time {
    width: 100%;
    margin: 0.125rem 0;
    font-size: 13px;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(4, 4, 19, 0.56);
}

.upcoming__item-topic {
    cursor: pointer;
    color: #000;
}

.upcoming__divider {
    width: 100%;
    height: 1px;
    background-color: #ededf4;
}

/*********************** upcoming footer */
.upcoming__footer {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 24px;
    vertical-align: middle;
    font-size: 13px;
    line-height: 16px;
    color: #747487;
    cursor: pointer;
}

@media screen and (max-width: 680px) {
    .upcoming {
        display: none;
    }
}