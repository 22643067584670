.sharedCallsSwitchList {
    max-height: 100vh;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    color: #232333;
    cursor: default;

    .sharedCallsSwitchList__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 8px 8px 8px 16px;
        margin: 4px 0;
    }

    .sharedCallsSwitchList__divider {
        width: 100%;
        height: 0.5px;
        background: #ededf3;
    }
}
