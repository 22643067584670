.modal-container {
    width: 400px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
    background-color: #FFFFFF; 
}

.header {
    display: flex;
    align-items: flex-start;
}

.header-logo {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: center center / 24px 24px no-repeat url(../../../assets/images/zoom-logo.png);
}

.header-txt {
    margin-left: 5px;
    font-weight: bold;
    font-size: 16px;
    width: 300px;
}

.content-text {
    font-size: 14px;
    margin-left: 29px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
}