.component_smart_captcha {
  .zmu-text-input__wrapper {
    border: 2px solid #0E72ED;
    input {
      background: transparent;
    }
  }

  .smart_tips {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131619;
  }

  .smart_container {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .captcha_code_layout {
      height: 40px;
      display: flex;
      margin-left: 15px;

      .captcha_display {
        display: flex;
        width: 80px;
        height: 100%;

        .captcha_code_img {
          height: 100%;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            border: 1px solid #F1F4F6;
            overflow: hidden;
            border-radius: 12px;
            outline: none;
          }
        }


        .captcha_code_audio {
          height: 100%;
          width: 100%;
          background: #F7F9FA;
          border: 1px solid #F1F4F6;
          border-radius: 12px;

          .btn_play {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000000;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            &:hover {
              background-color: transparent;
            }

            .text_play {
              margin-left: 4px;
            }

          }
        }
      }

      .captcha_action {
        display: flex;
        align-items: center;

        .action_button_item {
          padding: 0;
          width: 40px;
          height: 40px;
          border: 1px solid #DFE3E8;
          margin-left: 8px;
          border-radius: 10px;
        }

      }


    }
  }
}

