@import "../../../assets/styles/main.scss";
$text-color: #131619;

.lang-dialog {
    width: 480px;
    padding: 24px;
    color: $text-color;
    background: #ffffff;
    text-align: left;
    border: 1px solid #c1c6ce;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(19, 22, 25, 0.2), 0px 6px 12px rgba(19, 22, 25, 0.1);
    backdrop-filter: blur(10px);

    header {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        border: none;
    }

    .dialog-body {
        padding: 8px 0px 24px 0px;
    }

    .list-group {
        width: 100%;
        max-height: 224px;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(19, 22, 25, 0.5);
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .list-group-item {
        padding: 6px 8px;
        color: $text-color;
        text-align: left;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        &:hover {
            background-color: #f7f9fa;
        }
    }

    .list-group-item.active {
        background-color: #f1f4f6;
        background-image: url(../../../assets/images/Checkmark.png);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: right 18px top 10px;
    }

    footer {
        text-align: right;
        border: none;
        & > * {
            margin-left: 8px;
        }

        & > :first-child {
            margin-left: 0;
        }
    }
}
