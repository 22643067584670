.dialog {
    padding: 20px;
    max-width: 90vw;
    width: 580px;
}

header {
    color: #000;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 10px;
}

main {
    .item {
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .desc {
        display: inline-block;
        font-weight: 600;
        color: #000;
        font-size: 14px;
        margin-bottom: 2px;
    }

    .input {
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-radius: 10px;
        border: 1px solid #6e7680;
        padding: 0.2em 0.5em;
        &::focus {
            box-shadow: 0 0 0 2px #fff, 0 0 0 4px #0e72ed;
        }
        &::placeholder {
            font-size: 14px;
        }
    }

    .star {
        color: red;
    }

    .error {
        color: red;
        position: absolute;
        top: 100%;
        font-size: 12px;
    }
}

.toggle {
    margin-bottom: 10px;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    button {
        margin-right: 6px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
