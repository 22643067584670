@import '../../assets/styles/common-mixin.scss';

.zm-pwa-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    position: relative;
    cursor: pointer;
    user-select: none;
    
    .checkbox__input {
        width: 0;
        height: 0;
        opacity: 0;
    }

    .checkbox__mark {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        
        // border
        border: 1px solid #babacc;
        border-radius: 4px;
        
        // for svg icon component
        font-size: 10px;
        color: #fff;
    }

    .checkbox__text {
        margin-left: 8px;
    }
}


.checkbox--focused {
    .checkbox__mark {
       @include foucs-ring();
    }
}

.checkbox--checked {
    .checkbox__mark {
        background: #0e71eb;
        border: 0;
    }
}

.checkbox--disabled {
    .checkbox__mark {
        border: 1px solid #F1F4F6;
        outline: none;
        background: #D0E3FB;
    }
    
    .checkbox__text {
        color: #6E7680;
    }
}