.modal-add-to-camera-control-group {
    display: flex;
    flex-direction: column;

    width: 420px;
    padding: 24px;

    color:  #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DFE3E8;
    box-shadow: 0px 6px 12px 0px rgba(19, 22, 25, 0.10), 0px 12px 24px 0px rgba(19, 22, 25, 0.10);
}

.title {
    color: #131619;
    font-size: 16px;
    font-weight: 600;
}

.content {
    color: #131619;
    font-size: 14px;
}

.button-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.cancel {
    margin-right: 8px;
}

.add {
    background-color: #0E72ED;
    color:  #FFFFFF;
}