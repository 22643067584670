@import "../../assets//styles//common-mixin.scss";

.language-dropdown-box {
    display: flex;
    flex-direction: row;

    .dropdown-menu {
        padding: 8px 4px;
        border-radius: 12px;
        box-shadow: 0px 12px 24px rgba(19, 22, 25, 0.2), 0px 6px 12px rgba(19, 22, 25, 0.1);

    }

    .dropdown-box__content {
        overflow-y: auto;
        @include scroll-bar(6px);
        max-height: 232px;
    }


    .dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        height: 32px;
        padding-left: 30px;

        color: #131619;
        font-size: 14px;
        line-height: 20px;

        border-radius: 8px;
        &:hover {
            background-color: #f7f9fa;
        }
    }

    .dropdown-item.active {
        background-image: url(../../assets/images/Checkmark.png);
        background-size: 14px;
        background-repeat: no-repeat;
        background-color: #fff;
        background-position: left 8px top 9px;
        color: #131619;
        &:hover {
            background-color: #f7f9fa;
        }
    }

    .dropdown-item:active {
        color: #131619;
    }

    svg {
        margin-right: 3px;
    }

    .dropup {
        font-size: 14px;
    }

    &:not(.disabled) {
        .dropup {
            &:hover {
                background-color: #f7f9fa;
                border-radius: 6px;
            }
        }
    }

    &.disabled {
        .dropdown-toggle {
            cursor: not-allowed;
        }
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;

        background-color: transparent;
        border-color: transparent;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;

        padding: 2px 8px;

        &::after {
            display: none;
        }
    }

    .language-dropdown-box__title {
        color:  #6E7680;
        margin: 0 4px 0 5.33px;
    }
}

.icon-interpretation {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: center center / 100% 100% no-repeat url(../../assets/images/interpretation.svg);
}

.icon-arrow-up {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: center center / 100% 100% no-repeat url(../../assets/images/arrow-up-gray.svg);
}
