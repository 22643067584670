@import "../../../assets/styles/main.scss";

.if-join-meeting__main, .zwa-portal__confirm-delete__main {
    margin-bottom: 0;
    padding: 25px;
    width: 560px;
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgba(186, 186, 204, 0.2);
    box-shadow: rgb(35 35 51 / 10%) 0px 8px 24px;
}

.zwa-portal__confirm-delete__main {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.if-join-meeting__title, .zwa-portal__confirm-delete__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.if-join-meeting__content, .zwa-portal__confirm-delete__content {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
    white-space: normal;
}

.if-join-meeting__actions, .zwa-portal__confirm-delete__actions {
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 10px;
    }
}