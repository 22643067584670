.zmu-tabs__tab-container {
    justify-content: center;
    display: flex;
}

.zmu-tabs__tabs-list {
    display: inline-flex;
    justify-content: center;
    background-color: grey;
    width: 100%;
    height: 24px;
    background: rgba(82, 82, 128, 0.09);
    border-radius: 8px;
    font-size: 11px;
    font-weight: bold;
    color: #747487;
}

.zmu-tabs__tabpanel {
    flex-grow: 1;
}

.zmu-tabs__tab-bar {
    margin-right: 0;
    padding: 10px;
    flex-grow: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    &--active:not(.remote-active) {
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(34, 34, 48, 0.08);
        border-radius: 7px;
    }
    cursor: pointer;
}