.see-feedback-tip {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 28px;
  background: #f7f7fc;
  cursor: pointer;
  border-radius: 0px 0px 16px 16px;
  border: none;

  &:hover {
    background: rgba(82, 82, 128, 0.09);
  }

  .see-feedback-tip__svg {
    margin: 0 4px;
  }

  .see-feedback-tip__text {
    font-weight: 400;
    font-size: 14px;
    color: rgba(4, 4, 19, 0.56);
  }
}

.feedback-attention {
  width: 300px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .feedback-attention__hover {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #747487;
    margin: 0;
    line-height: 20px; /* 142.857% */
  }
}
