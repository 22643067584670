.contact-detail {
    border-left: 2px solid rgba(125, 125, 136, 0.13);
}

.contact-detail__empty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20%;

    i {
        display: inline-block;
        width: 200px;
        height: 200px;
        background: url("../images/contacts-detail-empty.png");
        background-size: 200px 200px;
    }

    .contact-detail__empty-txt {
        font-size: 14px;
        line-height: 14px;
        color: rgba(4, 4, 19, 0.56);
     }
}

.contact-detail__content {
    width: 100%;
    height: 100%;
    padding: 40px 30px;
}

.contact-detail__profile {
    display: flex;
    .contact-detail-left {
        flex-shrink: 0;
        flex-grow: 0;
        font-size: 42px;
        border-radius: 30px;
        width: 100px;
        height: 100px;
    }

    .contact-detail-avatar {
        border-radius: 30px;
    }

    .contact-detail-avatar-wrapper {
        width: auto;
        height: auto;
        position: relative;
    }

    .contact-detail-avatar__badge {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .contact-detail-right {
        padding: 0 30px;
        overflow: hidden;

        .contact-deatil-status {
            display: flex;
            align-items: center;
            font-size: 10px;

            .contact-detail-presence-txt {
                margin-left: 8px;
                font-size: 14px;
            }

            .contact-detail-meeting-presence {
                font-size: 12px;
            }
        }
        .contact--blocked {
            color: #E8173D;
            font-size: 14px;
        }

        .contact-detail-right-name {
            margin-top: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .contact-detail-right-pronoun {
            margin-top: 2px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            user-select: none;
        }
        .contact-detail-right-notes {
            margin-top: 2px;
            color: #6E7680;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            user-select: none;

        }

    }
}

.contact-detail__controls {
    width: 100%;
    margin-top: 24px;
    button {
        margin-right: 12px;
    }
}

.contact-detail__infolist {
    margin-top: 50px;
}

.contact-detail__info {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 12px;
    &-left {
        flex: 0 0 auto;
        width: 140px;
        line-height: 24px;
        color: #6E7680;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .contact-detail__info-href {
            margin-bottom: 12px;
        }
    }

    &-right {
        flex: 0 1 auto;
        color: #131619;
        line-height: 24px;
    }

    &-right.contact-detail__info-href {
        color: #0e72ed;
        text-decoration: none;
        cursor: pointer;
    }
}

.contact-detail__info-start {
    align-items: flex-start;
}
