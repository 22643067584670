.session-expired-timer__bar {
    display: block;
    height: 36px;
    background: #FFF9F2;
    font-size: 16px;
    color: #361E00;
    line-height: 36px;
    width: 100%;
    border: none;
    text-align: center;

    .bar__content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bar__content-time {
        margin-left: 4px;
        font-weight: 700;
        color: #333333;
    }

    .bar__sign-button {
        background: none;
    }
}