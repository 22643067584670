@import "../../../assets/styles/main.scss";

.modal-session-expire-time {
   

    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 400px;
    max-width: 100%;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    border-radius: 12px;
    background: #ffffff;
    text-align: left;

    .subtitle {
        font-weight: 700;
        font-size: 16px;
        color: #131619;
    }

    .content {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #131619;

        .content-time {
            font-weight: 700;
        }
    }

    .modal-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;

        .signin {
            margin-right: 10px;
            font-weight: bold;
        }

        .later, .signin {
            padding: 12px 10px;
        } 
    }


}