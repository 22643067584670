@import '../../../../assets/styles/abstracts/variables.scss';

.feedback-everyone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: $breakpoint-tablet) {
    height: 216px;
  }

  .feedback-everyone__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
  }

  .feedback-everyone__thumbs {
    width: 100%;
    display: flex;
    justify-content: space-around;
    cursor: pointer;

    @media only screen and (min-width: $breakpoint-tablet) {
      margin-bottom: 48px;
    }

    .feedback-everyone__thumbs__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: black;
      margin-top: 12px;

      @media only screen and (min-width: $breakpoint-tablet) {
        font-size: 14px;
        color: rgba(4, 4, 19, 0.56);
      }
    }
  }
}

.feedback-everyone__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;

  @media only screen and (min-width: $breakpoint-tablet) {
    height: auto;
  }

}