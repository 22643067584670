@import '../../../../assets/styles/abstracts/variables.scss';

.feedback-hadissues {
  text-align: center;

  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: $breakpoint-tablet) {
    width: auto;
    max-width: none;
    display: block;
  }

  .feedback-hadissues__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #222230;
    margin-bottom: 24px;
    margin-top: 56px;
  }

  .feedback-hadissues__content {
    // margin: 48px auto;

    .feedback-hadissues__content__items {
      user-select: none;
      width: 420px;
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex-direction: column;
      padding: 15px;

      @media only screen and (min-width: $breakpoint-tablet) {
        flex-direction: row;
        padding: 0;
      }
    

      .checkbox__text {
        font-size: 16px;

        @media only screen and (min-width: $breakpoint-tablet) {
          font-size: 14px;
        }
      }

      .zm-pwa-checkbox {
        margin-bottom: 16px;
        padding: 10px;
        border: 1px solid #d3d3d3;
        border-radius: 7px;

        // for small devices such as iPhone SE
        @media (orientation: portrait) and (max-height: 700px) {
          margin-bottom: 12px;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
          margin-bottom: 8px;
          padding: 0;
          border: none;
          border-radius: none;
        }

        &.checkbox--checked {
          background: #0d6efd;
          color: white;
          input:focus {
            outline: none;
          }

          @media only screen and (min-width: $breakpoint-tablet) {
            background: none;
            color: black;
            input:focus {
              outline: auto;
            }
          }
        }
      }

      .feedback-hadissues__content__items_item {
        width: 202px;
        font-style: normal;
        font-weight: 400;
        
        line-height: 24px;
        text-align: left;
        margin-left: 8px;
        cursor: pointer;

        .zm-pwa-checkbox-message {
          font-size: 14px;
        }

        &::before {
          width: 16px;
          height: 16px;
        }
      }
    }

    .feedback-hadissues__content__textinput {
      display: block;
      box-sizing: border-box;
      width: calc(100% - 30px);
      max-width: 430px;
      height: 64px;
      border-radius: 8px;
      border: 1px solid #909096;
      margin-top: 10px;
      resize: none;

      font-weight: 400;
      font-size: 16px;
      line-height: 17px;
      color: #222230;
      padding: 8px 12px;
      margin-left: auto;
      margin-right: auto;

      // for small devices such as iPhone SE
      @media (orientation: portrait) and (max-height: 700px) {
        margin-top: 0;
      }

      @media only screen and (min-width: $breakpoint-tablet) {
        width: 430px;
        font-size: 14px;
      }
    }

    .feedback-hadissues__content__button {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin: 24px auto;
    }

    .feedback-hadissues__content__customtext {
      width: 430px;
      color: #6e7680;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      word-break: break-all;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

.feedback-hadissues__content__items--column {
  display: flex;
  flex-direction: column;
}
