@import "../../assets/styles/main.scss";
@import "../../assets/styles/abstracts/color.scss";
@import '../SignedIn/Header/Header.scss';

.join-page {
    position: absolute;
    z-index: $join-meeting-z-index;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
}
.join-page {
    .home-header {
        padding: 0 $header-margin;
    }
}

.join {
    width: 60%;
    min-width: 300px;
    margin: 148px auto 0;
    .join-title {
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }

    .join-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .join-meetingId-container {
        width: 100%;
        position: relative;
        margin: 20px 0px;
        .join-arrow-down-container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
        .meeting-history {
            position: absolute;
            width: 100%;
            z-index: $meeting-history-list-z-index;
        }
    }

    .join-displayName-container {
        width: 100%;
        position: relative;
        margin-bottom: 28px;
        .join-displayName-tip {
            position: absolute;
            top: -8px;
            left: 10px;
            color: #0e72ed;
            background-color: #fff;
            font-size: 12px;
            padding: 0 4px;
        }
    }

    .join-meeting__dropdown-toggle {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover, &:focus {
            background-color: #e8e8e8;
        }
    }

    .join-meetingId,
    .join-displayName {
        width: 100%;
        height: 40px;
        padding: 10px 12px;
        border-radius: 10px;
        border: 1px solid #909096;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #222230;
        &::placeholder {
            color: rgba(4, 4, 19, 0.56);
        }
        &:focus {
            border: 1px solid #0e72ed;
        }
    }

    .join-displayName {
        margin-top: 0px;
    }

    .join-connect-audio,
    .join-off-video {
        align-self: flex-start;
        margin-top: 10px;
    }

    footer {
        width: 100%;
        margin-top: 20px;
        text-align: right;
        button {
            height: 32px;
            border: none;
            border-radius: 6px;
            font-size: 14px;
            line-height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &.btn-join {
                margin-left: 20px;
                &[disabled] {
                    background: #f2f2f7;
                    color: #909096;
                }
                &:not([disabled]) {
                    font-weight: 600;
                    background: #0e72ed;
                    color: #fff;
                    &:hover {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
                    }
                    &:active {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
                    }
                }
            }

            &.btn-cancel {
                margin-left: 20px;
                background: #fff;
                border: 1px solid #909096;
                &:hover {
                    background: rgba(82, 82, 128, 0.09);
                }
                &:active {
                    background: rgba(82, 82, 128, 0.18);
                }
            }
        }
    }
}

.join-page__footer {
    margin-top: auto;
}

@media screen and (min-width: 675px) {
    .join {
        width: 410px;
    }
}

.meeting-history-item__content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
    &.meeting-history-clear {
        padding: 0;
        .meeting-history-item__topic {
            color: inherit;
        }
    }
}

.tesla {
    .join-page {
        background-color: $darkmode-gray-bg;
    }

    .join-meeting__dropdown-toggle {
        background: rgba(68, 75, 83, 0.2);
        right: 5px;
        top: 0;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        font-size: 44px;
        margin: 10px;
        &:hover, &:focus {
            background: rgba(68, 75, 83, 0.2);
        }
    }

    .join {
        width: 794px;
        .join-title {
            font-weight: 500;
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 43px;
        }
        .join-meetingId-container {
            margin-top: 0;
            margin-bottom: 19px;
        }
        .join-meetingId {
            height: 64px;
            font-weight: bold;
            font-size: 22px;
            line-height: 32px;
            color: #6e7680;

            background-color: $darkmode-gray;
            border: 1px solid rgba(14, 114, 237, 1);
            color: $white;
            &::placeholder {
                color: $white;
            }
        }
        footer {
            margin-top: 0;
            button {
                height: 60px;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                width: auto;
                border-radius: 12px;
                padding: 0 90px;
            }
            .btn-cancel {
                background-color: $darkmode-gray;
                color: $white;
                &:hover {
                    background-color: $white;
                    color: $darkmode-gray;
                }
            }
            .btn-join {
                padding: 0 90px;
            }
        }
    }
    .meeting-history {
        top: 68px;
        background-color: #252A30;
        // margin: 13px 30px;

        .meeting-history-item {
            padding-left: 30px;
            padding-right: 30px;
            &:hover, &:focus {
                background-color: none;
            }
        }
        .meeting-history-list {
            padding-bottom: 13px;
        }
    }



    .meeting-history-item__topic {
        color: #F7F9FA;
        font-size: inherit;
        line-height: inherit;
    }

    .meeting-history-item__number {
        color: #98A0A9;
        font-size: inherit;
        line-height: inherit;
    }

    .meeting-history-item__content {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        border-bottom: 1px solid rgba(68, 75, 83, 0.8);
        font-size: 22px;
        line-height: 45px;
        margin-top: 10px;
        &:hover {
            color: #fff;
            background-color: rgb(14, 114, 237);
        }
        &.meeting-history-clear {
            border: none;
            padding: 0;
            .meeting-history-item__topic {
                color: inherit;
            }
        }
    }
}
