
@import '../../../assets/styles/abstracts/variables.scss';

.docsApp {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    micro-app {
        width: 100%;
        height: 100%;
    }

    micro-app-body {
        position: relative;
    }
}

.loading {
    position: absolute;
    z-index: $micro-app-cotainer-z-index;
    width: 100%;
    height: 100%;
    background-color: #fff;
    :global(.zmicon) {
        transform: translateY(-26px);
    }
}
