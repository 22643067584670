.pwa-tooltip {
    position: relative;
    padding: 0;
    width: 240px;
    height: 82px;
    color: #131619;
    font-size: 14px;
    line-height: 20px;
    filter: drop-shadow(0px 4px 8px rgba(19, 22, 25, 0.2)) drop-shadow(0px 2px 4px rgba(19, 22, 25, 0.1));
    backdrop-filter: blur(10px);
    
    .pwa-tooltip__bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
    
    .pwa-tooltip__inner{
        text-align: center;
        padding: 8px;
        width: 100%;
        height: 100%;
    }
    
    &[data-popper-placement^=bottom] {
        .pwa-tooltip__bg {
            transform: scale(1, -1);
        }
        
        .pwa-tooltip__inner {
            padding: 14px 8px 8px;
        }
    }
}