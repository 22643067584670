
.presence__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(40%, -40%);
        transform-origin: 100% 0%;

        width: 10px;
        height: 10px;
        font-size: 8px;
        border-radius: 100%;
        text-align: center;
        white-space: nowrap;
        // z-index: auto;

        background-color: #FFFFFF;
        border: 1px solid #FFF;
        padding: 5px;
}


.presence__video-icon {
        font-size: 10px;
        border-radius: 25%;
        padding-left: 6px;
        padding-bottom: 5px;
}