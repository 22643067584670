#upcoming-actions__dropdown {
    display: inline-block;
    .dropdown-toggle {
        display: inline-block;
        position: relative;
        border: none;
        background: none;
        outline: none;
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        font-size: 13px;
        padding: 6px 0;
        box-shadow: rgba(35, 35, 51, 0.1) 0px 8px 24px;
    }
    
    .dropdown-item {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        line-height: 32px;
        outline: none;
        border: none;
        &:focus,
        &.active,
        &:hover {
            color: white;
            background-color: #0e71eb;
        }
    }
}
