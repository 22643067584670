.button {
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

// size and font style
.button-size-24 {
    height: 24px;
    font-weight: normal;
}
.button-size-32 {
    height: 32px;
    font-weight: bold;
}
.button-size-40 {
    height: 40px;
    font-weight: bold;
}

// type
.button-normal {
    border: 0;
    color: #fff;
    background: #0e72ed;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }

    &:focus,
    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }

    &:disabled {
        color: #909096;
        background: #f2f2f7;
    }
}

.button-primary {
    border: 1px solid #0e72ed;
    color: #0e71eb;
    background: #ffffff;
    &:hover {
        border: 0;
        color: #ffffff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }

    &:focus,
    &:active {
        border: 0;
        color: #ffffff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }

    &:disabled {
        border: 0;
        color: #909096;
        background: #f2f2f7;
    }
}

.button-secondary {
    font-weight: normal;
    color: #222230;
    background: #ffffff;
    border: 1px solid #909096;
    &:hover {
        background: rgba(82, 82, 128, 0.09);
    }

    &:focus,
    &:active {
        background: rgba(82, 82, 128, 0.18);
    }

    &:disabled {
        color: #909096;
        background: #f2f2f7;
        border: 0;
    }
}

.button-destructive {
    border: 0;
    color: #ffffff;
    background: #de2828;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #de2828;
    }

    &:focus,
    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), #de2828;
    }

    &:disabled {
        color: #909096;
        background: #f2f2f7;
    }
}

.button-text {
    border: 0;
    color: #0e72ed;
    background: #ffffff;
    &:hover {
        color: #0862d1;
        background: #e7f1fd;
    }

    &:focus,
    &:active {
        color: #0862d1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #d7e6f8;
    }

    &:disabled {
        color: #909096;
        background: #f2f2f7;
    }
}
