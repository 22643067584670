@import "../../../assets/styles/main.scss";

.invitation {
    width: 324px;
    height: 160px;
    position: relative;
    padding: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #ffffff;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    border-radius: 12px;
    box-shadow: 0px 8px 24px rgba(35, 35, 51, 0.15);

    cursor: default;

    .invitation-close {
        position: absolute;
        right: 10px;
        top: 10px;

        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 2px;
        background: url(../../../assets/images/close.png);
        background-size: 16px 16px;
        background-position: center center;

        border-radius: 40%;
        color: black;
        cursor: pointer;
        &:hover {
            background-color: rgb(238, 232, 232);
        }
    }
    .invitation-content {
        display: flex;
        .invitation-avatar {
            flex: 0 0 auto;
            width: 48px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            border-radius: 15.84px;
        }

        .invitation-avatar-img {
            border-radius: 15.84px;
        }

        .invitation-detail {
            padding-left: 9px;
            min-width: 0;
            p {
                color: #222230;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
                margin: 0;

                &:first-child {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    margin: 0 0 4px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .invitation-footer {
        display: flex;
        justify-content: space-between;
        button {
            width: 134px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border-radius: 10px;
            color: #fff;
            border: none;
            cursor: pointer;
            font-size: 14px;
        }
        .invitation-join {
            background: #0e72ed;
            &:hover {
                background: #3c8cee;
            }
        }
        .invitation-decline {
            background: #de2828;
            &:hover {
                background: #e25959;
            }
        }
    }
}
