// @import "../../assets/styles/main.scss";

.modal-invite-contact {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 100%;
    background: #ffffff;
    text-align: left;
    padding: 24px;
    border: 1px solid #DFE3E8;
    box-shadow: 0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1);
    border-radius: 16px;

    .invite-content,
    .loading-content,
    .send-success-content {
        height: 170px;
    }

    .invite-content {
        display: flex;
        flex-direction: column;

        .title {
            color: #131619;
            font-weight: 600;
            font-size: 16px;
        }

        .email-address-text {
            color: #131619;
            font-weight: 500;
            font-size: 14px;

            margin-top: 8px;
            margin-bottom: 2px;
        }

        .email-input {
            padding-left: 10px;
            height: 35px;
            border: 1px solid #6E7680;
            border-radius: 8px;

            &:focus {
                outline: #0e72ed solid 2px !important;
            }
        }

        .email-input-error {
           border-color: #B10E2C;
        }

        .description {
            margin-top: 8px;
            height: 48px;
            font-weight: 400;
            font-size: 12px;
            color: #444B53;
        }

        .description-error {
            color: #B10E2C;
        }
    }

    .loading-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .invite-loading {
            font-size: 36px;
        }
    }

    .close-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .close-button {
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
        }

        .close {
            font-size: 14px;
            color: #000001;
        }
    }

    .already-contact-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        overflow: hidden;

        .avatar {
            width: 64px;
            height: 64px;
            font-size: 28px;
            border-radius: 22px;
        }

        .avatar-img {
            border-radius: 22px;
        }

        .name-text {
            font-size: 16px;
            font-weight: 600;
        }

        .tip {
            margin-top: 18px;
            margin-bottom: 26px;
            font-size: 16px;
            text-align: center;
            color: #131619;
        }
        
    }

    .send-success-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .invite-send-success{
            margin-bottom: 16px;
            width: 90px;
            height: 62px;
            background: url('../../../assets//images/plane.svg');
        }

        .success-text {
            color: #131619;
            font-size: 16px;
            text-align: center;
        }

        .email-text {
            display: block;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .delete-content {
        display: flex;
        flex-direction: column;

        .title {
            color: #131619;
            font-weight: 600;
            font-size: 16px;
        }

        .description {
            margin-top: 8px;
            height: 48px;
            font-weight: 400;
            font-size: 12px;
            color: #444B53;
        }
    }


    .modal-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: center;

        .invite,
        .delete {
            margin-right: 8px;
        }

        .invite,
        .ok {
            font-weight: 500;
        }

        .invite,
        .delete,
        .cancel {
            padding: 14px 16px;
        }

        .ok  {
            padding: 14px 20px;
        }

        .delete {
            background-color: #E8173D;
        }
    }

    .center {
        justify-content: center;
        align-items: center;
    }

}