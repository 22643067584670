.confirmDialog {
    width: 520px;
    max-width: 90vw;
    padding: 20px;
}

.header {

}

.body {
    padding: 0px 0 20px 0;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
        margin-right: 10px;
    }

    & > :last-child {
        margin-right: 0;
    }
}



