.feedback-thumb {
  .feedback-thumb__hover {
    display: none;
  }
  .feedback-thumb__active {
    display: none;
  }

  &:hover {
    .feedback-thumb__default,
    .feedback-thumb__active {
      display: none;
    }
    .feedback-thumb__hover {
      display: block;
    }
  }

  &:active {
    .feedback-thumb__default,
    .feedback-thumb__hover {
      display: none;
    }
    .feedback-thumb__active {
      display: block;
    }
  }
}
