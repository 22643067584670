@import "./assets/styles/common-mixin.scss";

.appLoading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.logo {
    width: 178px;
    height: 40px;
    margin-top: 148px;
    margin-bottom: 80px;
    background: url("./assets/images/zoom-trademark.png") no-repeat;
    background-size: contain;
}

@keyframes progress-bar-animation {
    0% {
        transform: translateX(-90%);
    }

    100% {
        transform: translateX(310px);
    }
}

@mixin progress-bar-animation {
    animation: progress-bar-animation 950ms ease-in-out 10ms infinite alternate;
}

.progressBar {
    position: relative;
    width: 320px;
    height: 4px;
    border-radius: 4px;
    background: rgba(241, 244, 246, 1);
    overflow: hidden;
}

.progressThumb {
    position: absolute;
    width: 40%;
    height: 4px;
    border-radius: 4px;
    background: rgba(14, 114, 237, 1);
    @include progress-bar-animation();
}

.loading {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: rgba(110, 118, 128, 1);
}