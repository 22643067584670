.general-settings {
    margin-bottom: 20px;
}

.general-header {
    font-size: 14px;
    font-weight: bold;

    &.general-header__theme{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    
    }
}

.theme-notice {
    font-size: 12px;
    color: #6E7680;
    display: flex;
    align-items: center;
    .theme-notice-text{
        margin-right: 5px;
    }
}

.color-themes {
    display: flex;
    font-size: 12px;
    .theme {
        display: flex;
        flex-direction: column;
        margin-right:20px;
        text-align: center;
        cursor: pointer;
    }
}
.theme-color{
    &.selected{
        border: 2px solid #0E72ED;
        border-radius: 22px;
    }
}


.reset-nav {
    margin-left: auto;
    cursor: pointer;
    color: #0E72ED;
    font-size: 12px;
    padding: 5px 7px;
    font-weight: bold;
    line-height: 14px;
    border-radius: 7px;

    &:hover {
        background-color: #0E72ED1F;
    }
}

.navigation-instructions {
    font-size: 12px;
    line-height: 24px;
    display: flex;
}
.zmu-btn {
    &.reset-nav {
        margin-left: auto;
        cursor: pointer;
        color: #0E72ED;
        font-size: 12px;
        padding: 5px 7px;
        font-weight: bold;
        line-height: 14px;
        border-radius: 7px;
        height:24px;
        border: none;
        &:hover {
            background-color: #0E72ED1F;
        }
    }
}
.screen-reader-hidden-text{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}