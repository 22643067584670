.add-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.add-calendar__container {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-calendar__icon {
    font-size: 13px; 
    color: #0e72ed;
}

.add-calendar__txt {
    margin-left: 4px;
    cursor: pointer;
    color: #0e72ed;
    &:hover {
        color: #0e72ed;
    }
}

.add-calendar-meetings-panel {
    padding: 10px 0;
}

.add-calendar-upcoming-empty {
    width: 100%;
    height: 100%;

    .add-calendar__container {
        text-decoration: none;
    }

    
    .add-calendar__icon {
        color: #131619;
    }

    .add-calendar__txt {
        color: #131619;
    }

    &:hover {
        .add-calendar__icon {
            color: #0e72ed;
        }
        
        .add-calendar__txt {
            color: #0e72ed;
        }
    }
}

.add-calendar-as-upcoming-footer {
    width: 100%;
    height: 100%;
    .add-calendar__container {
        text-decoration: none;
    }
}
