@import '../../assets/styles/abstracts/variables.scss';

.after-feedback {
    .after-feedback__message {
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        width: 300px;
        margin: auto;
    }

    .index-logo {
        margin: 32px auto auto;
        width: 200px;

        @media screen and (min-height: 600px) {
            margin: 48px auto auto
        }
    }

    .index__footer {
        margin: auto auto 8px auto;
    }

    // hide the language options using CSS for the thumbsup/thumbsdown survey
    .index__footer-divider,
    #language-dropdown-button {
        display: none;
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    .after-feedback {
        .after-feedback__message {
            font-size: 36px;
            width: 450px;
        }

        .index-logo {
            width: 300px;
        }

        .index__footer {
            margin: auto auto 16px auto;
        }
    }
}
