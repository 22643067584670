.meetings__host-filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meetings__host-filter-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    border-radius: 4px;
    cursor: default;
    &:hover {
        background: #e7f1fd;
    }
    &:active {
        background: #d4e6fc;
    }
}

.meetings__host-filter__prefix {
    margin-right: 4px;
    font-size: 13px;
    line-height: 14px;
    color: rgba(4, 4, 19, 0.56);
}

#meetings__host-filter__dropdown {
    font-size: 13px;
    line-height: 14px;
    .dropdown-toggle {
        position: relative;
        font-size: 13px;
        line-height: 14px;
        border: none;
        padding: 2px 4px;
        background: none;
        color: #000;
        &::after {
            display: inline-block;
            content: "^";
            transform: scale(1.6, 1) rotate(180deg) translateY(2px);
            margin-left: 5px;
            padding: 0;
            border: none;
            color: #999;
            vertical-align: 0;
        }
        &:active,
        &:focus {
            border: none;
            outline: auto;
        }
    }

    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    color: #747487;
    .dropdown-menu {
        font-size: 13px;
        padding: 6px 0;
        box-shadow: rgba(35, 35, 51, 0.1) 0px 8px 24px;
    }
    .dropdown-item {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        line-height: 32px;
        outline: none;
        border: none;
        &:focus,
        &:hover {
            color: white;
            background-color: #2d8cff;
        }
        &.active {
            color: rgb(81, 84, 98);
            background-color: rgb(227, 240, 253);
            &:focus,
            &:hover {
                color: white;
                background-color: #2d8cff;
            }
        }
        .checker {
            display: inline-block;
            width: 12px;
            height: 9px;
            background: center center / 100% 100% no-repeat url("../../../assets/images/check.svg");
        }
        
        &:hover .checker {
            
        }
    }
}