.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 8px 0;
    min-width: 100px;
    background: #FFFFFF;
    border: 1px solid #DFE3E8;
    box-shadow: 0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1);
    border-radius: 4px;

    .dangerous {
        color: #E8173D;
    }
}

.menuItem {
    // width: 100%;
    // text-align: left;
    // font-size: 12px;
    // padding: 2px 12px;
    // border: 0;
    // background-color: transparent;

    white-space: nowrap;
    line-height: 32px;
    padding: 0 16px;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    border: none;
    width: 100%;
    background-color: white;

    &:hover {
        background-color: #0e72ed;
        color: #FFFFFF;
    }
}

.groupLine {
    border-bottom: 1px solid #EDEDF4;
    margin: 8px 0;
    width: 100%;
}
