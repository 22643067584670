.index__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 16px auto;
}

.index__footer-about {
    padding: 2px 8px;
    border: none;
    border-radius: 6px;
    color: #6e7680;
    background: none;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
        background: #f7f9fa;
    }

    &:active {
        background: #f1f4f6;
    }
}

.index__footer-divider {
    width: 1px;
    height: 8px;
    background: rgba(82, 82, 128, 0.18);
    border-radius: 1px;
    margin: 0 16px;
}
