// ***************************************** global modal dialog [shadow/border] style */
@import "../../../assets/styles/main.scss";


.logout-modal {
    @include modal-inner-shadow();
    background: #ffffff;
    border: 0.5px solid rgba(135, 135, 51, 0.2);
    border-radius: 20px;
    padding: 32px;
    color: #232333;
    font-style: normal;
    width: 500px;
    max-width: 100%;
    margin-top: -10vh;
    
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin: 0 0 16px 0;
    }
    
    &__text {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
    }
    
    &__btns {
        text-align: right;
    }
    
    &__btn-ok {
        width: 84px;
        border-radius: 8px;
    }
}

.tesla {
    .logout-modal {
        background-color: #131619;
        color: #ffffff;
        border: 1px solid #252A30;
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5), 0px 6px 12px rgba(0, 0, 0, 0.5);
    }
    .pwa-modal__overlay--on {
        background-color: rgba(19, 22, 25, 0.75);
    }
}
