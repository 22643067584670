$padding: 30px;

.z-modal-confirm-outer-content {
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(38, 38, 38, 0.3);
    margin-top: -4rem;
    width: 755px;
    max-width: 80vw;
    min-width: 25vw;
    background-color: white;
}

.z-modal-confirm {
    max-width: 80vw;
}

.z-modal-confirm__header {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 6px;
}

.z-modal-confirm__header-title {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
}

.z-modal-confirm__header-logo {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: center center / 100% 100% no-repeat url(../../../assets/images/zoom-logo.png);
}

.z-modal-confirm__header-txt {
    font-size: 12px;
    margin-left: 6px;
}

.z-modal-confirm__header-close {
    margin-right: 2px;
    padding: 4px;
    font-size: 12px;

    &:active,
    &:hover {
        background-color: rgba(106, 106, 106, 0.1);
    }
}


.z-modal-confirm__overlay {
    background-color: rgba(255, 255, 255, 0.9);
}

.z-modal-confirm__body {
    padding: $padding $padding 0 $padding;
    h5 {
        font-weight: 600;
    }
}

.z-modal-confirm__body-desc {
    font-size: 13px;
}

.z-modal-confirm__footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 $padding $padding $padding;
    border: none;
    & > * {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    & > :last-child {
        margin-right: 0;
    }
}