.pwa {
    position: relative;
    margin: 0 auto;
    margin: auto;
    width: 100%;
    height: 100%;
}

[data-whatintent="mouse"] *:focus {
    outline: none!important;
}
