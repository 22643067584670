.home-main-new-meeting-option-panel {
    background: #fff;
    color: #232333;
    border: 1px solid #d3d3d3;
    box-shadow: 0 0 10px #d3d3d3;
    border-radius: 6px;
    text-align: left;
    padding: 5px 0;
    font-size: 14px;
    .new-meeting-option-panel-block {
        display: flex;
        align-items: center;
        padding: 16px;
        white-space: nowrap;
        border-bottom: 1px solid #ededf3;
        justify-content: space-between;
        cursor: pointer;
         
        &[aria-expanded='true'],
        &:hover {
            background: #0e71eb;
            color: #fff;
        }

        &.meeting-option__disabled:hover {
            background: initial;
            color: initial;
        }
        
        &:last-child {
            border-bottom: none;
        }

        &__no-border {
            border-bottom: none;
        }
    }
    
    .pmi-number {
       
        padding-left: 25px;
        margin-right: 25px;
    }
}