@mixin dialog-border-style {
    box-shadow: 0px 6px 12px 0px rgba(19, 22, 25, 0.1);
    box-shadow: 0px 12px 24px 0px rgba(19, 22, 25, 0.1);
    border: 1px solid rgba(223, 227, 232, 1);
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #DFE3E8, #DFE3E8);
}

.body {
    width: 400px;
    border-radius: 16px;
    padding: 24px;
    @include dialog-border-style();

    .img {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 16px;

        &.setUp {
            width: 332px;
            height: 200px;
            background-image: url('../../../assets/images//canNotShowPhone/setup.png');
        }

        &.upgrade {
            width: 352px;
            height: 200px;
            background-image: url('../../../assets/images//canNotShowPhone/upgrade.png');
        }

        &.support {
            width: 332px;
            height: 200px;
            background-image: url('../../../assets/images//canNotShowPhone/setup.png');
        }
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-bottom: 16px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        &> :last-child {
            margin-left: 8px;
        }
    }
}

.banner {
    width: 100%;
    height: 32px;

    background: rgba(0, 31, 69, 1);

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(242, 248, 255, 1);

    .link {
        margin-left: 0.625em;
        color: rgba(9, 86, 181, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
    }
}