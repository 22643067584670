$darkmode-gray-bg:  #131619;
$darkmode-gray: #252A30;
$white: #F5F5F5;
$zoom-blue: #0e71eb;

// outline
$outline-offset: 1px;
$outline-width: 2px;
$outline-color:  #2d8cff;
$outline-style: solid;

//red
$red-color-1: #de2828;

// header themes
$classic: #E2E6E9;
$bloom: #143584;
$agave: #11565A;
$rose: #84192F;
