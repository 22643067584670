.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_tos {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6E7680;
  a {
    color: var(--text-link-color);
    &:hover {
      color: var(--text-link-hover-color);
    }
  }
}

.CaptchaContainer {
  &.error {
    .zmu-text-input__wrapper {
      border-color: #E02828;
    }
  }
}
