.contacts-wrapper {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.contacts-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contacts-panel__header {
    display: flex;
    align-items: center;
    padding-right: 12px;
}

.contact-detail {
    display: none;
}

@media screen and (min-width: 645px) {
    .contacts-panel {
        width: 50%;
    }
    .contact-detail {
        display: block;
        width: 50%;
    }
}

@media screen and (min-width: 720px) {
    .contacts-panel {
        width: 360px;
        flex: 0 0 auto;
    }
    .contact-detail {
        display: block;
        flex: 1 1 auto;
    }
}