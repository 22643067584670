@mixin margin-right-for-children($right) {
    & > * {
        margin-right: $right;
    }

    & > :last-child {
        margin-right: 0;
    }
};

@mixin focus-outline() {
    &:focus {
        outline: 2px solid rgb(71, 147, 241);
        outline-offset: 2px;
    }

    &:disabled {
        outline: none;
    }
}

.z-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    padding: 6px;
    white-space: nowrap;

    @include focus-outline();
}

.z-button-size-24 {
    @include margin-right-for-children(4px);
    line-height: 20px;
    height: 24px;
    font-size: 13px;
    padding: 0 10px;
    border-radius: 6px;
}

.z-button-size-32 {
    @include margin-right-for-children(4px);
    height: 32px;
    line-height: 20px;
    font-size: 14px;
    padding: 0 20px;
    font-weight: bold;
    border-radius: 8px;
}

.z-button-size-40 {
    @include margin-right-for-children(8px);
    height: 40px;
    line-height: 24px;
    padding: 0 24px;
    font-weight: bold;
    border-radius: 10px;
}

.z-button-size-48 {
    @include margin-right-for-children(8px);
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    padding: 12px 24px;
    font-weight: 700;
    border-radius: 12px;
}

.z-button-type-normal {
    color: #ffffff;
    background: #0e72ed;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }
    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        color: #909096;
        background: #f2f2f7;
    }
}

.z-button-type-primary {
    border: 1px solid #0e72ed;
    color: #0e71eb;
    background: #ffffff;
    &:hover {
        border: 1px solid transparent;
        color: #ffffff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }
    &:active {
        border: 1px solid transparent;
        color: #ffffff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #0e72ed;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        border: 1px solid transparent;
        color: #909096;
        background: #f2f2f7;
    }
}

.z-button-type-secondary {
    color: #131619;
    background: #f1f4f6;
    &:hover {
        background: #dfe3e8;
    }
    &:active {
        background: #c1c6ce;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        background: #f1f4f6;
        color: #6e7680;
    }
}

.z-button-type-icon {
    color: #131619;
    background: none;
    padding: 0px;
    &:hover {
        background: #dfe3e8;
    }
    &:active {
        background: #c1c6ce;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        background: #f1f4f6;
        color: #6e7680;
    }
}

.z-button-type-destructive {
    color: #ffffff;
    background: #de2828;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #de2828;
    }
    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), #de2828;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        border: 1px solid transparent;
        color: #909096;
        background: #f2f2f7;
    }
}

.z-button-type-text {
    color: #0e72ed;
    background: #ffffff;
    &:hover {
        color: #0862d1;
        background: #e7f1fd;
    }
    &:active {
        color: #0862d1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #d7e6f8;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        border: 1px solid transparent;
        color: #909096;
        background: #f2f2f7;
    }
}

.z-button-type-dark {
    border: 1px solid #909096;
    color: #232333;
    background: #ffffff;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #ffffff;
    }
    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)), #ffffff;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
        color: #909096;
        background: #f2f2f7;
    }
}
