@import "../../../assets/styles/main.scss";

#chat-popover {
    min-width: fit-content;
    border: 1px solid #DFE3E8;
    border-radius: 8px;
    box-shadow: 1px 8px 24px rgb(51 51 51 / 30%);
    z-index: $chat-availbale-popover-z-index;
}

.chat-popover__action-close {
    margin-top: 2px;
    padding: 1px;
    height: 20px;
    width: 26px;
    font-size: 12px;
    border: none;
    border-radius: 30%;
    outline-offset: 4px;
    line-height: 6px;
    background: transparent;
    cursor: pointer;
    
    &:hover,&:focus{
        background-color: rgb(238, 232, 232);
    }
}