@import "../../assets/styles/main.scss";

.pwa-webclient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    box-sizing: content-box;
}

.pwa-webclient--mini {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(45px);
    border-radius: 8px;
    outline: 2px solid rgba(0, 0, 0, .8);
    outline-offset: 0;
}

.tesla {
    .pwa-webclient {
        background-color: #131619;
    }
}

.pwa-webclient {
    &__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: blue;
        text-align: center;
        z-index: 30;
    }

    &__loading-icon {
        width: 32px;
        height: 32px;
        margin: 0 auto;
        background: url(../../assets/images/loading-spiner.png);
        background-size: 32px 32px;
        @include rotate-infinite(1.5s);
    }
}

.pwa-webclient {
    &__back {
        display: flex;
        align-items: center;
        position: absolute;
        top: 32px;
        left: 38px;
        z-index: 30;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        border: none;
        background: transparent;
        color: #0e71eb;
        .pwa-webclient__back-icon {
            font-size: 20px;
            color: #0e71eb;
        }
    }
}

.pwa-webclient__iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: transparent;
}

.pwa-webclient__iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}

.pwa-webclient__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 40;
}