@import "../../../../assets/styles/abstracts/variables.scss";

.home-header__info-panel {
    position: absolute;
    top: 45px;
    right: 0px;
    z-index: $header-info-panel-z-index;
    width: 226px;
    padding: 10px 0 0 0;

    background: #fff;

    border: 1px solid #d3d3d3;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    text-align: left;
    font-size: 14px;
    line-height: 18px;
    color: #232333;

    cursor: default;

    .info-panel-block {
        padding: 5px 0;

        button {
            display: flex;
            align-items: center;

            width: 100%;
            border: none;
            padding: 8px 16px;
            background: #fff;
            text-align: left;

            &:focus-visible {
                position: relative;
                z-index: 1;
            }

            &:hover {
                cursor: pointer;
                background: #0e71eb;
                color: #fff;
            }

            i.contact-detail-presence,
            img {
                margin-right: 8px;
            }
        }
    }

    .info-panel-detail {
        padding: 0 16px 11px;

        span {
            display: block;
            width: 100%;
            line-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:first-child {
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .info-panel-settings {
        .info-panel-settings-button {
            display: flex;
            align-items: center;

            .settings-text-icon {
                margin-right: 8px;
                color: #828282;
            }

            &:hover {
                .settings-text-icon {
                    color: #FFFFFF;
                }
            }
        }
    }

    .lang__button {
        justify-content: space-between;
    }

    .lang__button:disabled {
        .inline_lang {
            color: inherit;
        }
    }

    .inline_lang {
        color: #6e7680;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    button:hover {
        .inline_lang {
            color: #fff;
        }
    }

    .info-panel-divider {
        width: 100%;
        height: 0.5px;
        background: #ededf3;
    }
}

.dnd-duration {
    position: relative;

    .dnd-duration__list {
        position: absolute;
        left: -150px;
        top: -10px;
        z-index: 1;
        width: 150px;
        padding: 10px 0;
        background: #fff;
        border: 1px solid #d3d3d3;
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        color: #232333;
        cursor: default;
    }
}

.dnd-duration__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dnd-duration__title__text {
        display: flex;
        align-items: center;
    }

    .dnd-duration__title__duration {
        font-size: 12px;
    }
}

.presence-icon {
    font-size: 12px;
    margin-right: 8px;
}

.dnd-away__button:hover {
    .away-text-icon {
        path {
            fill: #FFFFFF;
        }
    }
}

.presence-busy-button {
    .busy-text-icon {
        color: #FF5C5C;
    }
}

.info-panel-status .contact-detail-presence-dnd {
    background-color: white;
}
