@import "../../../assets/styles/main.scss";

.modal-ato {
    width: 400px;
    max-width: 100%;
    background: #ffffff;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    @include modal-inner-shadow();
    border-radius: 16px;
    text-align: left;
    padding: 24px 24px 24px 24px;
    p {
        font-size: 14px;
        line-height: 20px;
        margin: 0 auto 8px auto;
    }

    td {
        font-size: 14px;
    }

    td:nth-child(1) {
        width: 4.6em;
        color: gray;
    }

    .signing-in {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
    }

    .button-group {
        text-align: right;

        button {
            border-radius: 8px;
            margin-top: 20px;
            margin-right: 8px;
        }
    }
}
