@import "../../assets/styles/abstracts/color.scss";

$tesla-bg-color: #111111;

.tesla-app {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;

    .drive-warning {
        border-radius: 8px;
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #f7f9fa;
        padding: 8px 12px;
        background: $darkmode-gray;
    }

    .main {
        background-color: $tesla-bg-color;
        .drive-warning {
            width: 600px;
            position: absolute;
            bottom: 46px;
            margin: 0;
        }
    }

    .tesla-footer {
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #f7f9fa;
        margin-bottom: 46px;
    }

    .tesla-footer__action {
        cursor: pointer;
        color: #4793F1;
    }

    .index {
        background-color: $tesla-bg-color;
    }

    .index .index-logo {
        margin-bottom: 87px;
        margin-top: 301px;
    }

    .btns-index {
        button {
            width: 404px;
            height: 60px;
            border-radius: 12px;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
        }
    }

    .index .btn-index-signin {
        margin-top: 25px;
    }

    .home-header {
        height: 100px;
        background-color: $tesla-bg-color;
        border: none;
    }

    .home-header__avatar {
        font-size: 24px;
        right: 20px;
    }

    .header-avatar-presnece {
        transform: scale(1.5);
    }

    .home-header__info-panel .info-panel-detail span:first-child {
        font-size: 16px;
        line-height: 24px;
    }

    .home-header__info-panel .info-panel-detail span:last-child {
        font-size: 13px;
        line-height: 16px;
    }

    .home-header__info-panel {
        background-color: $darkmode-gray;
        border: 1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
        color: $white;
        border-radius: 8px;
        top: 81px;
    }

    .home-header__info-panel .info-panel-block > button {
        background: $darkmode-gray;
        color: $white;
        font-size: 13px;
        line-height: 16px;
        &:hover {
            background: #0e71eb;
        }
    }
    .info-panel-divider {
        background: rgba(255, 255, 255, 0.18);
    }

    .btn-index-signin {
        background: none;
        color: rgba(247, 249, 250, 1);
        &:hover {
            background-color: $white;
            color: $darkmode-gray;
        }
    }
}

// ************************************ for home page
.tesla-app {
    .main__actions .main__action-join .main__action-btn,
    .main__actions .main__action-return .main__action-btn,
    .main__actions .main__action-schedule .main__action-btn,
    .main__actions .main__action-start .main__action-btn {
        width: 88px;
        height: 88px;
        border-radius: 30px;
    }

    ._avatar__pic-wrapper {
        border-radius: 30%;
    }

    .main__actions .main__action-start,
    .main__actions .main__action-join,
    .main__actions .main__action-return,
    .main__actions .main__action-schedule {
        width: 88px;
    }

    .main__actions .main__actions-row > :nth-child(2) {
        margin-left: 112px;
    }

    .main__actions .main__action-join .main__action-btn {
        background-size: 48px 48px;
    }

    .main__actions .main__action-start .main__action-btn {
        background-size: 56px 32px;
    }
    
    .start-label__icon {
        background-color: $darkmode-gray;
        color: #f7f9fa;
        &:focus,
        &:hover {
            outline: 1px solid #4f5760;
        }
    }
}

// tesla Terms of Service modal

.tesla {
    .term-privacy-dialog {
        width: 1083px;
        height: 800px;
        padding: 51px 0 19px;
    
        .term-privacy__line--bold {
            font-weight: bold;
        }
    }
    
    .term-privacy-dialog__content {
        overflow-y: scroll;
        overflow-x: auto;
        height: 100%;
        padding: 0 80px; 
    }
    
    .tesla-footer__modal {
        background: $darkmode-gray;
        border: 1px solid #c1c6ce;
        box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.2), 0px 12px 24px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        p,
        p font {
            color: white;
        }
        .modal-dialog__close-icon {
            position: absolute;
        }
    }

    .tesla-footer__overlay {
        background-color: #131619;
    }

    .modal__mask {
        display: none;
    }
}

// for scrollbar
.tesla {
    ::-webkit-scrollbar {
        background: none;
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        background: none;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        height: 100px;
        width: 0px;
        background-color: #bbb;
        box-shadow: inset 0 0 0px #000;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
}


.tesla {
    background-color: $darkmode-gray-bg;

    .modal-about {
        width: 600px;
        height: 286px;
        background: $darkmode-gray;
        border: 1px solid #444b53;
        box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.2), 0px 12px 24px rgba(0, 0, 0, 0.1);
        p,
        .about-close {
            color: white;
        }
        padding-top: 75px;
    }
    .modal-dialog__close-icon {
        display: inline-block;
        width: 22px;
        height: 22px;
        background-size: 22px 22px;
        right: 51px;
        top: 22.5px;
    }
    
    .modal-about .about-zoom-logo {
        width: 149px;
        height: 33px;
        background-size: 149px 33px;
    }
    .modal-about p {
        font-size: 16px;
    }
    .modal-dialog__close-icon {
        position: absolute;
        background-image: url("../../assets/images/close-white.png");
    }

    .join footer button.btn-join {
        margin-left: 15px;
    }

    .main__actions > div span {
        color: $white;
        font-size: 20px;
    }

    .modal__content {
        background: none;
    }

    .about-close {
        &:hover {
            background: none;
        }
        .about-close-icon {
            background-image: url("../../assets/images/close-white.png");
            width: 14px;
            height: 14px;
            background-size: 14px 14px;
        }
    }

    .join footer button.btn-join[disabled] {
        background: $darkmode-gray;
        color: #6e7680;
    }

    .join footer button.btn-cancel {
        border: 1px solid #6e7680;
    }

    
}
