@import "../../../assets/styles/common-mixin.scss";

$item-bg-color-hover: #e7f1fd;
$item-bg-color-selected: #0e71eb;
$item-label-color: #747487;
$item-topic-color: #232333;
$item-txt-color: $item-label-color;

$detail-topic-color: #39394d;
$detail-txt-color: #232333;

.meetings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.meetings__groups-divider {
    height: 1px;
    margin: 0 36px;
    background-color: #ededf4;
}

.add-calendar__divider {
    height: 1px;
    background-color: #ededf4;
    width: 100%;
}

.meetings__groups {
    flex: 1 1 auto;
    width: 100%;
    height: 0; // to make overflow work.
    overflow: auto;
    @include scroll-bar();
}

.meetings__groups-empty {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(4, 4, 19, 0.56);
}

.meetings__group {
    width: 100%;
}

.meetings__group-pmi {
    margin: 0 16px;
    .meetings__group-label {
        background-color: bisque;
    }
}

.meetings__pmi-divider {
    height: 1px;
    margin: 0 36px 10px;
    background-color: #ededf4;
}

.meetings__pmi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 78px;
    border-radius: 12px;
    margin: 0 16px;

    border: none;
    background-color: #FFFFFF;

    .meetings__pmi-number,
    .meetings__pmi-txt {
        margin: 0;
        padding: 0;
    }

    .meetings__pmi-number {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 4px;
        color: $item-topic-color;
    }
    .meetings__pmi-txt {
        font-size: 13px;
        line-height: 16px;
        color: $item-txt-color;
    }

    &:hover {
        background-color: $item-bg-color-hover;
    }

    &--selected,
    &--selected:hover {
        background-color: $item-bg-color-selected;
        .meetings__pmi-number,
        .meetings__pmi-txt {
            color: #ffffff;
        }
    }
}

.meetings__group-label {
    margin: 0 16px;
    padding: 12px 20px;
    font-weight: bold;
     font-size: 13px;
    line-height: 16px;
    color: $item-label-color;
}

.meetings__meeting-item {
    padding: 8px 20px;
    margin: 10px 16px;
    border-radius: 12px;
    color: $item-txt-color;
    font-size: 13px;
    line-height: 16px;
    &:hover {
        background-color: $item-bg-color-hover;
    }

    &--selected,
    &--selected:hover {
        background-color: $item-bg-color-selected;
        color: #fff;
        .meetings__meeting-item-topic {
            color: #fff;
        }
    }
}


.meetings__meeting-item-topic,
.meetings__meeting-item-time,
.meetings__meeting-item-host,
.meetings__meeting-item-meeting-id {
    margin: 8px 0;
}

.meetings__meeting-item-topic {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $item-topic-color;
    overflow-wrap: break-word;
}

.meetings__detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 48px 4px 40px 40px;
}

.meetings__detail-info {
    flex: 0 0 auto;
}

.meetings__detail-topic {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $detail-topic-color;
    margin-bottom: 32px;
}

.meetings__detail-private,
.meetings__detail-time,
.meetings__detail-host,
.meetings__detail-number {
    font-size: 13px;
    line-height: 16px;
    color: $detail-txt-color;
    margin: 16px 0;
}

.meetings__detail-private {
    font-size: 14px;
    line-height: 20px;
    span {
        margin-right: 8px;
    }
}

.meetings__detail-time-notice {
    color: #0e72ed;
}

.meetings__detail-time-notice-now {
    color: rgb(253, 76, 76);
}

.meetings__detail-btns {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    margin: 32px 0;
    & > * {
        margin-right: 16px;
        margin-bottom: 10px;
    }
    
    .meetings__detail-btn__icon-l {
        font-size: 12px;
    }
}

.meetings__detail-btn-msg-channel,
.meetings__detail-btn-copy,
.meetings__detail-btn-edit {
    border: 1px solid #dfe3e8;
    color: #131619;
    background-color: #fff;
    
    &:hover {
        color: #0e71eb;
        background-color: #f7f9fa;
    }

    &:focus {
        color: #0e71eb;
        background-color: #f1f4f6;
    }
    
    &.disabled {
        color: #6e7680;
        background-color: #fff;
    }
}

.meetings__detail-btn-delete {
    border: 1px solid #dfe3e8;
    color: #131619;
    background-color: #fff;
    &:hover {
        color: #e02828;
        background-color: #f7f9fa;
    }

    &:focus {
        color: #e02828;
        background-color: #f1f4f6;
    }

    &.disabled {
        color: #6e7680;
        background-color: #fff;
    }
}

.meetings__detail-invitation {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
}

.meetings__detail-invitation-link {
    border: none;
    background: none;
    font-size: 13px;
    line-height: 8px;
    color: #0e72ed;
    cursor: pointer;
    margin: 12px 0 16px 0;
    &:hover {
        text-decoration-line: underline;
        color: #0e71eb;
    }
}

.meetings__detail-invitation-content {
    flex: 1 1 auto;
    height: 0;
    overflow: auto;
    margin-bottom: 0;
    align-self: stretch;
    word-break: break-word;
    white-space: break-spaces;
    font-size: 13px;
    @include scroll-bar(6px);
    .z-loading {
        margin: 10px 20px;
    }
}

.meetings__detail-empty,
.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meetings__detail-empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.meetings-empty-icon {
    display: inline-block;
    width: 200px;
    height: 200px;
    background: center center / 100% 100% no-repeat url("../../../assets/images/calendar.png");
}

.meetings-empty-tips {
    font-size: 14px;
}
