._avatar {
    color: #fff;
    position: relative;
    border-radius: 10px;
    &__pic-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
    }

    &__name {
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    &__pic {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 8px;
    }

    .header-avatar-presence{
        display: none;
    }
}

i.unknown-avatar-icon {
    height: 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.default-profile-icon {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

