.meetings-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}
.meetings-container__left {
    flex: 0 0 auto;
    position: relative;
    height: 100%;
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.meetings-container__v-divider {
    flex: 0 0 auto;
    height: 100%;
    width: 2px;
    background: rgba(125, 125, 136, 0.13);
}

.meetings-container__right {
    height: 100%;
    flex: 1 1 auto;
}

.meetings-container__left-header__refresh {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 4px;
    font-size: 13px;
    background-color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.meetings-container__left-header__add {
    width: 16px;
    height: 16px;
}

.meetings-container__left-body {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
}

.meetings-container__left-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    .zmu-tabs__tab-container {
        padding: 16px;
        text-align: center;
        .zmu-tabs__tabs-list {
            flex: 0 0  auto;
            width: 60%;
        }
    }
}