@import '../../assets/styles/common-mixin.scss';

@mixin button-hover-animation {
    transform: translateY(0px);
    transition: transform 0.15s ease, box-shadow 0.15s ease;

    &:hover,&:focus {
        transform: translateY(-4px);
        box-shadow: 0px 4px 11px 0px #b3b3b3;
        transition: transform 0.15s ease, box-shadow 0.15s ease;
    }
}

@mixin button-disabled {
    &:disabled {
        box-shadow: unset;
        transform: unset;
        transition: unset;   
        cursor: auto;
   }
}

.main {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > :nth-child(2) {
        margin-left: 100px;
    }
}

.new-meeting-arrow-down-container {
    padding: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border-radius: 40%;
    &:hover {
        background: #f2f2f7;
    }
}
.new-meeting-arrow-down {
    display: inline-block;
    border: solid rgba(4, 4, 19, 0.46);
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg) translate(-1px, -1px);
}

.main__actions {
    .main__actions-row {
        display: flex;
        justify-content: flex-start;
        height: 120px;
        & > :nth-child(2) {
            margin-left: 60px;
        }

        &:not(:first-child) {
            margin-top: 48px;
        }
    }

    .main__action-start,
    .main__action-join,
    .main__action-return,
    .main__action-schedule {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        width: 88px;
        text-align: center;
        font-size: 14px;
        color: #6E7680;

        .main__action-btn {
            @include button-hover-animation();
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 88px;

            padding: 22px;
            margin-bottom: 16px;
            
            border: none;
            border-radius: 30px;
            
            font-size: 44px;
            cursor: pointer;
        }

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
        }

        &.disabled {
            span {
                color: #909096;
            }
        }
    }

    .main__action-start,
    .main__action-return {
        .main__action-btn {
            color: #fff;
            background-color: #ff742e;
            &:active {
                background-color: #e56829;
            }
            &:disabled {
                @include button-disabled();
                background-color: #F9CBB7;

            }
        }
    }

    .main__action-join,
    .main__action-schedule {
        .main__action-btn {
            color: #fff;
            background-color: #0e71eb;
            &:active {
                background-color: #0c63ce;
            }
            &:disabled {
                @include button-disabled();
                background-color: #B1C7F6;
            }
        }
    }
}

@media screen and (max-width: 280px) {
    .main {
        .main-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .main-item {
                margin-left: 0;
                margin-right: 0;
            }
            .home-main-new-meeting-option-panel {
                left: -30px;
                width: 180px;
                .new-meeting-option-panel-block {
                    white-space: normal;
                }
            }
        }
    }
}

.main__action-schedule button[disabled] {
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background: center center/100% 100% no-repeat url(../../assets/images/loading.png);
        @include rotate-infinite(1s);
        z-index: 1;
        width: 50%;
        height: 50%;
    }
}