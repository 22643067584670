.chat-post-popover {
    width: 368px;
    padding: 16px;
}

.chat-post__content-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.chat-post__content {
    color: #131619;
    header {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    footer {
        display: flex;
        justify-content: flex-end;
    }
}

.chat-post__content-body {
    margin: 4px 0 12px;
}

.chat-post__content-topic {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
