.reAuth {
    width: 100%;
    padding: 0.625em;

    background-color: #fff2f5;
    color: #000;

    font-size: 14px;

    line-height: 1.25;

    p {
        margin: 0 0 0.325em 0;
    }
}

.anchor {
    color: #0e72ed;
    border: 0;
    background: none;
    padding: 0;
    text-decoration: none;
}
