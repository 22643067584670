@import "../../../assets/styles/abstracts/color.scss";

.tesla-app {
    .upcoming {
        width: 449px;
        height: 512px;
        background: #252a30;
        border: 1px solid rgba(82, 82, 128, 0.09);
        border-radius: 12px;
        
        &__header-time {
            font-size: 48px;
            font-weight: 500;
        }

        &__header-day {
            font-size: 16px;
            font-weight: 500;
        }

        &__content {
            padding: 0;
            color: $white;
            background: $darkmode-gray;

            &-empty {
                font-size: 20px;
            }
        }

        &__detail {
            color: $white;
            font-size: 16px;

            &-topic {
                color: $white;
                font-size: 22px;
                line-height: 1.25;
                padding-bottom: 0;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box; 
                -webkit-box-orient: vertical; 
                -webkit-line-clamp: 2;
                &-wrapper {
                    padding: 5px 0;
                    align-items: center;
                }
            }

            .meetings__detail-time {
                color: $white;
                font-size: 16px;
                margin-top: 10px;
            }

            .upcoming__actions {
                margin-top: 16px;
            }
        }

        &__item {
            &-topic {
                color: $white;
                font-size: 20px;
                line-height: 21px;
            }

            &-time {
                color: $white;
                font-size: 14px;
            }
        }
    }

    .upcoming__detail {
        padding: 20px 24px;
    }
    
    .upcoming__actions {
        margin-top: 16px;
    }

    .upcoming-btn-tesla {
        width: 100%;
    }
    
    .upcoming__footer {
        height: 60px;
        border-radius: 0px 0px 0px 32px;
        background-color: transparent;
        border: none;
    }
}
