.index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .index-logo {
        width: 300px;
        height: 116px;
        flex-shrink: 0;
        margin-top: 148px;
        margin-bottom: 112px;
    }
    .btns-index {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .btn-index-join {
        width: 240px;
    }
    .btn-index-signin {
        margin-top: 20px;
        width: 240px;
    }

    .index__footer {
        margin-top: auto;
    }
}
