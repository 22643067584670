.bgColor0 {
  background-color: #268543;
}

.bgColor1 {
  background-color: #007C7C
}

.bgColor2 {
  background-color: #2974A8
}

.bgColor3 {
  background-color: #9053C2
}

.bgColor4 {
  background-color: #555B62
}

.bgColor5 {
  background-color: #B36200
}

.bgColor6 {
  background-color: #9D3B0F;
}

.bgColor7 {
  background-color: #E8173D
}

// deactive, deleted
.bgColorDeactive {
  background-color:  #909096B3;
}
