@mixin restyle-ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

@mixin dialog-style {
    background: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 0 0 10px #d3d3d3;
    border-radius: 6px;
}

.start-pmi__menu-wrapper {
    position: absolute;
    padding: 0 20px;
}

.start-pmi__menu {
    @include restyle-ul();
    @include dialog-style();
    color: #232333;
    text-align: left;
    padding: 10px 0;
    font-size: 14px;
}

.start-pmi__menu-item {
    height: 32px;
    line-height: 32px;
    padding: 0 24px;
    cursor: pointer;

    &:hover {
        background: #0e71eb;
        color: #fff;
    }
}
