.chat-app {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex: 1 1 auto;
    overflow: auto;

    &.chat-app-not-matched {
        :global(#chat-root){
            display: none;
        }
        
        :global(#root) {
            display: none;
        }

        height: 0;
        flex: 0 0;
    }
}

.fallback {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global(#chatSearchRoot) {
    & > div:first-child {
        border-bottom: none;
    }
}
