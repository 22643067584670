@import "../../assets/styles/main.scss";
@import "../../assets//styles//abstracts/variables.scss";

//due to the pwa modal z-index too large, pwa modal will be on top of  @zoom/pwa-settings tooltip, so it's has to overwrite @zoom/pwa-settings tooltip z-index
.zmu-tooltip__container {
    .zmu-tooltip {
        z-index: 1010;
    }
}

.modal-dialog__close-icon {
    display: block;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    background-image: url("../../assets/images/close.png");
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.settings-dialog {
    position: relative;
    margin: 0;
    background-color: white;
    width: 800px;
    height: 660px;
    background-color: white;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.0757473);
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    .modal-dialog__close-icon {
        position: absolute;
        right: 15px;
        top: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 16px;
        height: 16px;

        color: #222230;
        border-width: 0px;;
        background-color: transparent;
        font-size: 16px;

    }

}

.settings-dialog__header {
    cursor: move;
    font-size: 20px;
    padding: 13px 16px;
    line-height: 20px;
}
.settings-dialog__hr {
    border-top: 1px solid #ededf4;
    position: relative;
}

.settings-body {
    flex: 1 1 0;
    height: 0; // to make flex decide it's height

    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    .left-sidebar {
        width: 220px;
        border-right: 1px solid #ededf4;
        height: 100%;
        padding: 16px;
        text-align: left;
        flex-shrink: 0;
    }
    .right-content {
        flex-grow: 1;
        padding: 32px;
        padding-bottom: 0;
        text-align: left;
        overflow: auto;
        @include scroll-bar(6px);
    }
}

.settings-dialog__backdrop-dialog {
    pointer-events: none;
}

.settings-content__divider {
    border-bottom: 1px solid #EDEDF4;
    margin-bottom: 16px;
    margin-top: 16px;
}



.settings-icon {
    margin-right: 8px;
    padding: 3px;
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #FFFFFF;
}


.settings-nav-item.nav-link {
    display: flex;
    align-items: center;
    &.active {
        background: #0E71EB;
        .settings-icon {
            background-color: initial;
        }
    }
    border-radius: 12px;
    height: 32px;
    &:not(.active):hover {
        background-color: rgb(247, 243, 243);
    }
    margin-top: 6px;
    margin-bottom: 6px;
}

.settings-icon__general {
    font-size: 14px;
    padding: 2px;
    background-color: rgb(130, 199, 134);
}

.settings-icon__audio {
    font-size: 14px;
    padding: 2px;
    background-color: rgb(130, 199, 134);
}

.settings-icon__video {
    font-size: 14px;
    padding: 2px;
    background-color: rgb(130, 199, 134);
}

.settings-icon__phone {
    background-color: #85C0A2;
}

.settings-icon__statistics {
    background-color: #C182D1;
}

.settings-icon__chat {
    background-color: #70C3A0;
}

// please refer to christopher.wang@zoom.us and afei.gao@zoom.us
body .react-select__menu-portal {
    z-index: $pwa-settings-popup-z-index;
    position: absolute;
}
