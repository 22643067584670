@import "../../../assets/styles/main.scss";

.modal-about {
    width: 100%;
    max-width: 480px;
    background: #ffffff;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    @include modal-inner-shadow();
    border-radius: 12px;
    text-align: center;
    padding: 64px 46px 24px 46px;
    .about-zoom-logo {
        width: 106px;
        height: 24px;
        background-size: 106px 24px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        color: rgba(4, 4, 19, 0.56);
        margin: 0 auto;
        &.about-version {
            line-height: 24px;
            color: #222230;
            margin: 24px 0;
        }
    }
    .about-close {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 4px;
        border: none;
        background: transparent;
        outline-offset: 4px;
        border-radius: 30%;
        &:hover {
            background-color: rgb(238, 232, 232);
        }
        .about-close-icon {
            display: block;
            font-size: 12px;
        }
    }
    .about__open-source {
        margin-top: 54px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #0e72ed;
        outline-offset: 4px;
        .about__open-source-link-icon {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-size: 12px 12px;
            background-image: url(../../../assets/images/open-link.png);
            margin-left: 6px;
        }
    }
}


/*
    width: 480px;
    max-width: 100%;
    background: #ffffff;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    box-shadow: rgb(35 35 51 / 10%) 0px 8px 24px;
    border-radius: 12px;
    text-align: left;
    padding: 46px 46px 24px 46px; */