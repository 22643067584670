.dialog {
    width: 580px;
    max-width: 90vw;
    padding: 30px;
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
}

.inputItem {
    margin-bottom: 30px;
}

.select {
    margin-bottom: 30px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    & > * {
        display: inline-block;
        // Todo, it's dangerous to use important !!!
        margin-left: 20px!important;
    }

    & > :last-child {
        margin-left: 0;
    }
}
