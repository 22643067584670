
@import "../../../assets/styles/common-mixin.scss";

$HistoryItemHeight: 32px;
.meeting-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(186, 186, 204, 0.2);
    box-shadow: 0px 8px 24px rgba(35, 35, 51, 0.1);
    border-radius: 8px;
    top: 45px;
    
    .meeting-history-list {
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;
    }
    
    .meeting-history-item {
        height: $HistoryItemHeight;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        
        font-size: 13px;
        line-height: 24px;
        user-select: none;
        // &:hover {
        //     color: #fff;
        //     background-color: rgba(14,114,237,0.85);
        // }
        
        span {
            flex: 0 0 auto;
            &:first-child {
                flex: 1 1 auto;
                padding-right: 24px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        &:hover, &:focus {
            color: #fff;
            background-color: rgba(14,114,237,1);
            outline-offset: 0;
            .meeting-history-item__topic, .meeting-history-item__number {
                color: #ffffff;
            }
        }
    }
    
    .meeting-history-clear {
        flex: 0 0 auto;
        width: 100%;
        height: $HistoryItemHeight;
        color: #4793F1;
        cursor: pointer;
        font-weight: 500;
    }
}

.meeting-history-item__topic {
    color: #131619;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.meeting-history-item__number {
    color: #6E7680;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.meeting-history-list-virtualized {
    @include scroll-bar();
}