.trans__meeting-container {
    width: 312px;
    background-color: #F7F7FC;
    border-radius: 12px;
    padding: 16px;
    margin: 0 auto 16px;
}

.trans__meeting-status {
    font-size: 14px;
    color: #DE2828;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.trans__meeting-status-icon {
    margin-right: 8px;
}

.trans__meeting-topic {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.trans__meeting-topic-text {
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px;
    line-height: 20px;
}

.trans__meeting-device {
    font-size: 14px;
    color: #747487;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
}

.trans__meeting-error {
    width: 400px;
    height: 360px;
    background-color: #ffffff;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    border-radius: 12px;
    box-shadow: 0px 8px 24px rgba(35, 35, 51, 0.15);
    padding: 32px;
    font-size: 14px;
    .z-button {
        float: right;
        margin-top: 170px;
    }
}

.trans__meeting-error-title {
    font-weight: bold;
    font-size: 20px;
}


.tesla {
    .trans__meeting-container {
        width: 394px;
        background-color: rgba(255, 255, 255, 0.09);
        margin-top: 16px;
        margin-bottom: 0;
    }
    .trans__meeting-status {
        color: #FF5C5C;
        font-size: 16px;
    }
    .trans__meeting-topic-text {
        font-size: 22px;
        line-height: 26px;
    }
    .trans__meeting-device {
        color: #909096;
        font-size: 20px;
        line-height: 24px;
    }
    .trans__meeting-error {
        width: 480px;
        height: 400px;
        color: #ffffff;
        background-color: #131619;
    }
    .trans__meeting-error-title {
        font-size: 28px;
    }
    .trans__meeting-error-desc {
        font-size: 22px;
    }
}