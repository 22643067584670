@import '../../../assets/styles/common-mixin.scss';

.dialog {
    width: auto;
    height: auto;
    min-width: 60px;
    min-height: 60px;

    background-color: #fff;
    border-radius: 12px;
    @include modal-inner-shadow();
}
