.helpBtn {
    position: relative;
};

.menuContainer {
    position: absolute;
    left: -150px;
    top: -10px;

    width: 150px;
    padding: 10px 0;
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    color: #232333;
    cursor: default;
}
