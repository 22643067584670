.start-label {
    display: inline-flex;
    align-items: center;
}

.start-label__icon {
    margin-left: 4px;
    border: none;
    border-radius: 30%;
    display: inline-flex;
    padding: 0.125em;
    cursor: pointer;
    background: none;
    color: rgba(4, 4, 19, 0.56);
    
    &:focus,
    &:hover {
        background-color: #f2f2f7;
    }
}