.banner1 {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(233, 241, 252);
}

.details1 {
    margin-right: 80px;
    font-size: 12px;
    line-height: 16px;
}

.details1Device {
    color: #e8173d;
    display: flex;
    align-items: center;
}

.details1Topic {
    font-size: 14px;
    font-weight: 700;
}

.bannerMoreThan1 {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(233, 241, 252);
}

.bannerMoreThan1Popover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:first-child) {
        margin-top: 15px;
    }
}

.details2Popover {
    margin-right: 30px;
    font-size: 12px;
    line-height: 16px;
}

.details2 {
    margin-right: 30px;
    font-size: 12px;
    line-height: 16px;
    color: rgb(37, 85, 175);
}

