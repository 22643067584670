@import './abstracts/color.scss';
@import "./common-mixin.scss";

* {
    box-sizing: border-box;
 }

*:focus {
    @include foucs-ring();
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: system-ui, "SF Pro", "Segoe UI", "Almaden Sans", Roboto, Ubuntu, Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden; // for scrollbar
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body > #root {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.arrow-down-container {
    padding: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    &:hover {
        background: #f2f2f7;
    }
}

.arrow-down {
    display: inline-block;
    border: solid rgba(4, 4, 19, 0.46);
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg) translate(-1px, -1px);
}

.zoom-logo {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
    background-image: url(../images/zoom-logo.png);
}

.zoom-trademark {
    display: inline-block;
    width: 178px;
    height: 40px;
    background-size: 178px 40px;
    background-image: url(../images/zoom-trademark.png);
}

.zoom-workplace-dark {
    display: inline-block;
    width: 75px;
    height: 26px;
}

.zoom-workplace-light-tesla {
    display: inline-block;
    width: 134px;
    height: 47px;
}

.aria-hidden {
    position: absolute;
    left: -9999px;
    top: -9999px;
    width: 1px;
    height: 1px;
    z-index: -999;
}

.z-loading {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: center center/100% 100% no-repeat url(../images/loading.png);
    @include rotate-infinite(1s);
}

.black-lock-icon {
    display: inline-block;
    width: 8px;
    height: 10px;
    background: center center/100% 100% no-repeat url("../images/black-lock.svg");
}

.overflow-ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-ellipsis-2 {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.overflow-ellipsis-3 {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}


micro-app-body {
    height: 100%;
}
