.home {
    position: relative;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.home [data-main-body] {
    flex: 1 1 auto;
    overflow: auto;
    micro-app-body {
        height: 100%;
    }
}

.home-main__container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 0;
}

.home-main_container-left {
    position: relative;
    display: flex;
    min-width: 0; // let browser decides it's width based on flex model
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

#sidebarCachePoint {
    position: fixed;
    overflow: hidden;
    border: 1px solid #80808026;
    border-radius: 8px;
    display: none;
    z-index: 1;
}
