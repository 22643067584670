.cloud-record__hint {
    line-height: 32px;
    display: flex;
    align-items: center;
    background: #F7F9FA;
    width: 100%;
    color: #131619;
}

.cloud-record__hint--left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}