.pwa-wb {
    position: relative;
    width: 100%;
    height: 100%;
}

.pwa-wb__loading {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background: #fff;
}

.pwa-wb__loading__icon {
    font-size: 36px; 
    &.zmicon-spin > svg {
        animation-duration: 1.5s;
    }
}

.pwa-wb__loading__text {
    margin-top: 30px;
}

.pwa-wb__content {
    width: 100%;
    height: 100%;
    border: none;
}

.pwa-wb__iframe {
    width: 100%;
    height: 100%;
    border: none;
}