$Bg_Color: #F0EBEC;
$Black: #000000;
$White: #ffffff;
$Box_Border_Gray: #DFE3E8;

@mixin dialog-box-style() {
    width: 400px;
    background: $White;
    border: 1px solid $Box_Border_Gray;
    box-shadow: 0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 24px;
}

.dialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $Bg_Color;
    color: $Black;
    @include dialog-box-style();
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin: 0;
}

.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 8px 0 24px 0;
}

.btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > :first-child {
        margin-right: 1.5rem;
    }
}