@import '../../../../assets/styles/abstracts/variables.scss';

.feedback-container {
  width: 100vw;
  max-width: 480px;
  background: white;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 16px;
    border: 1px solid #DFE3E8;
    /* Shadow/Light mode/Medium */
    box-shadow: 0px 6px 12px 0px rgba(19, 22, 25, 0.10), 0px 12px 24px 0px rgba(19, 22, 25, 0.10);
    backdrop-filter: blur(5px);
    max-height: 100dvh;
    min-height: 308px;
  }

  .modal-dialog__close-icon {
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;

    @media only screen and (min-width: $breakpoint-tablet) {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }

  .checkbox--checked .checkbox__mark {
    @media only screen and (max-width: $breakpoint-tablet) {
      outline-style: none;
    }
  }

  .see-feedback-tip {
    position: absolute;
    bottom: 0;
    max-width: 100vw;
    border-radius: 0;

    @media only screen and (min-width: $breakpoint-tablet) {
      position: static;
      bottom: auto;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.feedback-footer {
  width: 100%;
  position: absolute;
  bottom: 72px;
  left: 0;
  text-align: center;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
