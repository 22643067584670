.contacts-search {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    .contacts-search-icon {
        position: absolute;
        top: 19px;
        left: 16px;
        font-size: 14px;
        color: rgba(4, 4, 19, 0.56);;
    }
    input {
        width: 100%;
        height: 32px;
        padding: 10px 10px 10px 25px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        border: 1px solid #98A0A9;;
        border-radius: 8px;
        outline: none;
        &:focus {
            border: 1px solid #0E71EB;
            color: #232333;
        }
    }
}
