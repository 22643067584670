.loading-calendar-sidebar {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: #fff;
    color: #808080b3;
    & > i {
        font-size: 48px;
        margin-bottom: 20px;
    }
}
