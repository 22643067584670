.container {
    position: relative;
    height: 40px;
}



.container.inline {
    display: inline-block;
}

.container.block {
    display: block;
}

.input {
    border-radius: 6px;
    border: 1px solid #eee;

    width: 100%;
    height: 100%;

    padding: 4px;
}

.container.errored {
    input {
        border-color: red;
    }
}
.error {
    position: absolute;
    top: 100%;
    left: 0;
    color: red;
    font-size: 14px;
}
