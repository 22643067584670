@import '../../../../assets/styles/abstracts/variables.scss';

.feedback-randomly {
  min-height: 385px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .feedback-randomly__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
    margin-top: 56px;
  }

  .feedback-randomly__content {
    height: 260px;

    @media only screen and (min-width: $breakpoint-tablet) {
      height: 228px;
    }

    .feedback-randomly__content__textinput {
      display: block;
      width: 100%;
      max-width: 400px;
      height: 144px;
      border: 1px solid #0e72ed;
      box-sizing: border-box;
      border-radius: 8px;
      resize: none;
      display: block;
      box-sizing: border-box;
      padding: 8px 12px;
      margin-bottom: 16px;

      font-weight: 400;
      font-size: 16px;
      line-height: 17px;
      color: #222230;

      @media only screen and (min-width: $breakpoint-tablet) {
        font-size: 14px;
      }
    }

    .feedback-randomly__content__checkbox {
      width: 274px;
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #131619;

      &::before {
        width: 16px;
        height: 16px;
      }
    }

    .feedback-randomly__content__email {
      height: 36px;
      width: 100%;
      max-width: 400px;
      resize: none;
      margin-top: 10px;
      border: 1px solid #0e72ed;
      border-radius: 8px;
      display: block;
      padding: 6px 12px;

      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #222230;

      @media only screen and (min-width: $breakpoint-tablet) {
        height: 24px;
        font-size: 14px;
        padding: 0 12px;
      }
    }

    .feedback-randomly__content__notification {
      color: #e02827;
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .feedback-randomly__button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 32px;

    @media only screen and (min-width: $breakpoint-tablet) {
      font-size: 14px;
    }
  }
}
