
.dropdown__select-toggle {
    display: block;
    padding: 0 10px 0 20px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    text-align: left;
    line-height: 16px;
    background-color: transparent;
    border: 1px solid #909096;
    border-radius: 8px;
    cursor: default;

    &::after {
        display: none;
    }
}

.dropdown__select-toggle-container {
    display: flex;
}

.dropdown__select-toggle-title {
    flex-grow: 1;
    color: #000000;
}

.dropdown__select-toggle-arrow {
    font-size: 16px;
    color: #222230;;
}

.dropdown__rotate-180deg{
    transform: rotate(180deg);
}

.dropdown__select-menu {
    width: 100%;
    padding: 1px;
    border-radius: 8px;
}

.dropdown__select-menu-item{
    display: block;
    height: 32px;
    font-size: 14px;
    color: #000000;
    text-align: left;
    border-radius: 8px;
}
