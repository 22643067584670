@import "../../../assets/styles/main.scss";

.modal-forced-break-meeting {
    @include modal-inner-shadow();

    display: flex;
    flex-direction: column;
    padding: 40px 35px 24px 35px;
    width: 600px;
    max-width: 100%;
    border: 0.5px solid rgba(35, 35, 51, 0.2);
    border-radius: 12px;
    background: #ffffff;
    text-align: left;

    .subtitle {
        margin-bottom: 12px;
        color: #232333;
        font-weight: 700;
        font-size: 24px;
    }

    .subtitle-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }

    .special-offer {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 26px;
        padding: 24px 18px;
        border: 2px solid #4793F1;
        border-radius: 12px;
        
        .tag {
                position: absolute;
                left: -2px;
                top: -12px;
                
                padding: 1px 8px;
                height: 22px;
                font-weight: 600;
                font-size: 14px;
                text-align: center;
                color: #FFFFFF;
        
                background: linear-gradient(90deg, #0956B5 0%, #1C837E 52.82%, #18883A 100%);
                border-radius: 6px;
        
            }

        .star-icon {
            font-size: 16px;
            color: #FFBF39;
        }

        .desc {
            margin-left: 18px;
            font-weight: 500;
            font-size: 16px;
            color: #232333;
        }
    }

    .modal-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;

        .upgrade {
            margin-right: 26px;
            font-weight: bold;
        }

        .cancel, .upgrade {
            padding: 12px 10px;
        } 
    }


}