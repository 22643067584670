.contact-operation__button {
    display:  flex;
    align-items: center;
    justify-content: center;

    height:24px;
    width: 24px;

    background-color: #FFFFFF;
    border: 1px solid #98A0A9;
    border-radius: 6px;

    &:hover {
        background-color: #F7F9FA;
    }

    &:focus {
        background-color: #F1F4F6;
    }
}

.contact-operation__button-icon{
    color: #131619;
    font-size: 14px;
    cursor: pointer;
}

.contact-opertation__menu {
    position: fixed; // will be overriden by style
    padding: 4px;
    background-color:#FFFFFF;
    border: 1px solid #DFE3E8;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(19, 22, 25, 0.1), 0px 2px 4px rgba(19, 22, 25, 0.1);
}

.contact-opertation__menu-item {
    display: block;
    width: 100%;
    height: 32px;

    font-size: 14px;
    text-align: left;

    background-color: transparent;
    border-color: transparent;
    border-radius: 8px;

    &:hover, &:active{
        background-color: #0e72ed;
        color: #FFFFFF;
    }
}
